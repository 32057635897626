<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3 {{type}} Job {{type === 'Edit' ? ' - #' + form.id : ''}}
							el-popover(
								placement="top-start"
								:width="480"
								title="Cron Jobs"
								trigger="hover"
							)
								el-divider
								label.d-block
									p
										b Description:&nbsp;
										p Cron jobs are scheduled jobs. You can run these jobs based on a scheduler, using either Linux style cron invoker strings or node-cron ones.
										p For reference:&nbsp;
											a(href="https://crontab.guru") Linux Cron Jobs,&nbsp;
											a(href="https://www.npmjs.com/package/node-cron") node-cron syntax
								i.el-icon-question.chooseable.no-deco(slot="reference")
			el-form
				el-row.mt-2
					el-col(:span="24")
						h3.text-left Basic
				el-row(:gutter="24")
					el-col(:xs="24", :sm="customFrequencyFlag ? 6 : 8")
						el-form-item(label="Type" :error="errors.type.s ? errors.type.m : ''" v-bind:class="{'is-invalid': errors.type.s}")
							el-input(v-model="form.type", placeholder="Type (E.g FTD)")

					el-col(:xs="24", :sm="customFrequencyFlag ? 6 : 8")
						el-form-item(label="Status" :error="errors.status.s ? errors.status.m : ''" v-bind:class="{'is-invalid': errors.status.s}")
							el-select.w-100(filterable="", v-model="form.status" )
								el-option(value="" label="- Select Status -" )
								el-option(v-for="o of options.status" :value="o.v" :label="o.t" v-if="o.t !== 'PROCESSING'")

					el-col(:xs="24", :sm="customFrequencyFlag ? 6 : 8")
						el-form-item(label="Frequency" :error="errors.frequency.s ? errors.frequency.m : ''" v-bind:class="{'is-invalid': errors.frequency.s}")
							el-select.w-100(filterable="", v-model="form.frequency" )
								el-option(value="" label="- Select Frequency -" )
								el-option(v-for="o of options.frequency" :value="o.v" :label="o.t")

					el-col(v-if="customFrequencyFlag" :xs="24", :sm="6")
						el-form-item(label="Type" :error="errors.frequency_raw.s ? errors.frequency_raw.m : ''" v-bind:class="{'is-invalid': errors.frequency_raw.s}")
							el-input(v-model="form.frequency_raw", placeholder='"0 */3 * * * *"')

				el-row.mt-2(:gutter="24")
					el-col(:span="24")
						h3.text-left Integration

					el-col(:xs="24", :sm="6")
						el-form-item(label="Fetch Type")
							el-select.w-100(filterable="", v-model="form.fetch_type" @change="cleanStates('all')")
								el-option(v-for="o of options.fetch_type" :value="o.v" :label="o.t")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Brand" :error="errors.brand_id.s ? errors.brand_id.m : ''" v-bind:class="{'is-invalid': errors.brand_id}")
							el-select.w-100(filterable="", v-model="form.brand_id" @change="cleanStates('funnel')")
								el-option(value="" label="- Select Brand -" )
								el-option(v-for="o of options.brand" :value="o.v" :label="o.t")

					el-col(v-if="form.fetch_type === 'FUNNEL'" :xs="24", :sm="6")
						el-form-item(label="Funnel" :error="errors.funnel_id.s ? errors.funnel_id.m : ''" v-bind:class="{'is-invalid': errors.funnel_id.s}")
							el-select.w-100(filterable="", v-model="form.funnel_id" :disabled="form.brand_id === ''" @change="checkExists(form.fetch_type)")
								el-option(value="" label="- Select Funnel -" )
								el-option(v-for="o of options.funnel_extra" v-if="o.b === form.brand_id"	:value="o.v" :label="o.t + `${o.n ? ` (${o.n})` : ''}`")

					el-col(v-if="form.fetch_type === 'INTEGRATION'" :xs="24", :sm="6")
						el-form-item(label="Integration" :error="errors.integration_id.s ? errors.integration_id.m : ''" v-bind:class="{'is-invalid': errors.integration_id.s}")
							el-select.w-100(filterable="", v-model="form.integration_id" :disabled="form.brand_id === ''" @change="checkExists(form.fetch_type)")
								el-option(value="" label="- Select Funnel -" )
								el-option(v-for="o of options.integration_extra" v-if="o.b === form.brand_id"	:value="o.v" :label="o.t + `${o.n ? ` (${o.n})` : ''}`")

					el-col( :xs="24" :sm="3")
						el-form-item(label="In Use")
							el-switch.w-100(v-model="form.is_active" active-text="On" inactive-text="Off")


				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'job-list'})") Cancel


</template>


<style lang="scss">

</style>


<script lang="js">
import Vue from 'vue';
import validator from 'validator';
import { isValidCron } from 'cron-validator'

const $form = {
	fetch_type: 'FUNNEL',
	brand_id: "",
	frequency: "",
	frequency_raw: '',
	funnel_id: "",
	integration_id: "",
	is_active: false,
	status: "",
	type: "",
};

const $errors = {
	brand_id: { m: '', s: false },
	frequency: { m: '', s: false },
	frequency_raw: { m: '', s: false },
	funnel_id: { m: '', s: false },
	integration_id: { m: '', s: false },
	is_active: { m: '', s: false },
	status: { m: '', s: false },
	type: { m: '', s: false },
}

function cleanPayload(body) {
	const payload = JSON.parse(JSON.stringify(body));
	delete payload.uuid;
	if(payload.fetch_type === 'FUNNEL') {
		payload.ident = body.funnel_id;
	} else if(payload.fetch_type === 'INTEGRATION') {
		payload.ident = body.integration_id;
	}

	delete payload.hidden;
	delete payload.funnel_id;
	delete payload.integration_id;
	return payload;
}

	export default {
		name: 'job-edit',
		async beforeRouteEnter(to, from, next) {
			if (to.name !== 'job-create' && to.name !== 'job-edit') return next('/');
			if (to.name === 'job-edit') {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '9e832481-2c11-4021-ba13-0dcc5487e3f7,0ffc8009-affa-4154-82b4-6d33bb0dfb22,1f3ffe33-ebc8-47ce-9dd4-e5b12feb19e6,aaa02ae1-427a-4409-a639-2521773d74a6,49f06cec-46b0-4237-bf9a-d4d3969e5af6,1e0c327a-73cf-4159-b154-ec498ade6dcd'});
				let entity = await Vue.apix.sendHttpRequest('GET', 'jobs/' + to.params.id)
				next(vm => {

					vm.type = 'edit';
					vm.options = {};
					vm.options.status = options.job_status;
					vm.options.frequency = options.frequency;
					vm.options.brand = options.brand;
					vm.options.funnel_extra = options.funnel_extra;
					vm.options.fetch_type = options.fetch_type;
					vm.options.integration_extra = options.integration_extra;
					vm.form = { ...$form, ...entity };
					vm.formOrig = {};
					vm.formOrig.brand_id = entity.brand_id
					vm.formOrig.funnel_id = entity.funnel_id
					vm.formOrig.integration_id = entity.integration_id
					if(!(options.frequency.map(e => e.v).includes(entity.frequency_raw))) {
						vm.form.frequency = '-1';
					}
				});
			} else if (to.name === 'job-create') {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '9e832481-2c11-4021-ba13-0dcc5487e3f7,0ffc8009-affa-4154-82b4-6d33bb0dfb22,1f3ffe33-ebc8-47ce-9dd4-e5b12feb19e6,aaa02ae1-427a-4409-a639-2521773d74a6,49f06cec-46b0-4237-bf9a-d4d3969e5af6,1e0c327a-73cf-4159-b154-ec498ade6dcd'});
				next(vm => {
					vm.type = 'create';
					vm.options = {};
					vm.options.status = options.job_status;
					vm.options.frequency = options.frequency;
					vm.options.brand = options.brand;
					vm.options.funnel_extra = options.funnel_extra;
					vm.options.fetch_type = options.fetch_type;
					vm.options.integration_extra = options.integration_extra;
				});
			}
		},
		computed: {
			customFrequencyFlag() {
				return this.form.frequency === "-1"
			}
		},
		data() {
			return {
				options: {
					status: [],
					frequency: [],
					brand: [],
					funnel_extra: [],
					integration_extra: [],
					fetch_type: [],
				},
				type: 'Create',
				formOrig: { brand_id: '', funnel_id: '', integration_id: '' },
				form: $form,
				errors: $errors,
			}
		},
		methods: {
			cleanStates(type) {
				switch(type) {
					case 'all':
						this.form.integration_id = '';
						this.form.funnel_id = '';
						this.form.brand_id = '';
						break;
					case 'integration':
						this.form.integration_id = '';
						break;
					case 'funnel':
						this.form.funnel_id = '';
						break;
				}
			},
			checkExists(type) {
				switch(type) {
					case 'FUNNEL': {
						if(this.form.brand_id === this.formOrig.brand_id && this.form.funnel_id === this.formOrig.funnel_id) return;
						this.errors.funnel_id = { s: false, m: '' };
						this.errors.brand_id = { s: false, m: '' };
						let loader = this.$loading.show();
						this.$apix.sendHttpRequest('GET', 'jobs/job-exists', { fetch_type: 'FUNNEL', brand_id: this.form.brand_id, ident: this.form.funnel_id, type: this.form.type })
								.then(res => {
									loader.hide();
									if(res === -1) {
										this.$notify.warning({title: 'Notice', message: 'Job for this brand and this funnel already exist'});
										this.errors.funnel_id = { s: true, m: 'Duplicate job' };
										this.errors.brand_id = { s: true, m: 'Duplicate job' };
									}
								}).catch(e => loader.hide())
						break;
					}
					case 'INTEGRATION': {
						// console.log('TODO')
						if(this.form.brand_id === this.formOrig.brand_id && this.form.integration_id === this.formOrig.integration_id) return;
						this.errors.integration_id = { s: false, m: '' };
						this.errors.brand_id = { s: false, m: '' };
						let loader = this.$loading.show();
						this.$apix.sendHttpRequest('GET', 'jobs/job-exists', { fetch_type: 'INTEGRATION', brand_id: this.form.brand_id, ident: this.form.integration_id, type: this.form.type })
								.then(res => {
									loader.hide();
									if(res === -1) {
										this.$notify.warning({title: 'Notice', message: 'Job for this brand and this integration already exist'});
										this.errors.funnel_id = { s: true, m: 'Duplicate job' };
										this.errors.brand_id = { s: true, m: 'Duplicate job' };
									}
								}).catch(e => loader.hide())
						break;
					}
				}

			},
			async submit() {
				if(!await this.validate()) return;
				let loader = this.$loading.show();
				if(this.customFrequencyFlag) 	this.form.frequency = this.form.frequency_raw;
				delete this.form.frequency_raw;
				if(this.type === 'create') {
					this.$apix.sendHttpRequest('POST', 'jobs/', cleanPayload(this.form))
							.then(res => {
								setTimeout(() => {
									this.$notify({title: 'Success', message: `Job successfully created`});
									loader.hide()
									this.$router.push({name: 'job-list'});
								}, 5000);
							})
							.catch(() => loader.hide());
				} else {
					let override = this.customFrequencyFlag ? { frequency: this.form.frequency_raw } : {};
					this.$apix.sendHttpRequest('PUT', 'jobs/' + this.form.uuid, cleanPayload(this.form))
						.then(res => {
							setTimeout(() => {
								this.$notify({title: 'Success', message: `Job successfully updated`});
								loader.hide()
								this.$router.push({name: 'job-list'});
							}, 5000);
						})
							.catch(() => loader.hide());
				}
			},
			async validate() {
				this.errors = JSON.parse(JSON.stringify($errors));
				let error = false;

				if(this.form.type === '') {
					error = true;
					this.errors.type = { s: true, m: "This field cannot be empty" };
				}

				if(this.form.status === '') {
					error = true;
					this.errors.status = { s: true, m: "You must select an option" };
				}

				if(this.form.frequency === '') {
					error = true;
					this.errors.frequency = { s: true, m: "You must select an option" };
				} else if(this.customFrequencyFlag && this.form.frequency_raw === '') {
					error = true;
					this.errors.frequency_raw = { s: true, m: "You must specify a cron string" };
				} else if(this.customFrequencyFlag && this.form.frequency_raw !== '' && !isValidCron(this.form.frequency_raw, { seconds: true })) {
					error = true;
					this.errors.frequency_raw = { s: true, m: "Invalid cron string" };
				}

				if(this.form.brand_id === '') {
					error = true;
					this.errors.brand_id = { s: true, m: "You must select an option" };
				}

				if(this.form[`${this.form.fetch_type.toLowerCase()}_id`] === '') {
					error = true;
					this.errors[`${this.form.fetch_type.toLowerCase()}_id`] = { s: true, m: "You must select an option" };
				} else {
					if(this.form.brand_id === this.formOrig.brand_id && this.form[`${this.form.fetch_type.toLowerCase()}_id`] === this.formOrig[`${this.form.fetch_type.toLowerCase()}_id`]) return !error;
						let loader = this.$loading.show();

					let res = await this.$apix.sendHttpRequest('GET', 'jobs/job-exists', { fetch_type: this.form.fetch_type, brand_id: this.form.brand_id, ident: this.form[`${this.form.fetch_type.toLowerCase()}_id`], type: this.form.type })
					loader.hide();
					if(res === -1) {
						error = true;
						this.errors[`${this.form.fetch_type.toLowerCase()}_id`] = { s: true, m: 'Duplicate job' };
						this.errors.brand_id = { s: true, m: 'Duplicate job' };
					}
				}

				return !error;
			}
		}
	}
</script>
